
import ComponentButton from './component-button'
export default
	props:
		card: Object
		size: String
		textOverImage: Boolean

	components: { ComponentButton }

	computed:
		classes: -> [@size, 'url' if @card.url, 'text-over-image' if @textOverImage]
		aspect: -> switch @size
			when "small" then 1
			when "medium" then 0.8
			when "large" then 1.25

		hasComponentButton: ->
			!!@card.innerButtonText and (@card.modalImageDesktop?.length || @card.modalImageMobile?.length || @card.innerButtonUrl)

	methods:
		playMedia: ->
			return unless !@card.autoplay
			video = @$refs?.media?.$el?.querySelector 'video'
			return unless video
			video.play()

		pauseMedia: ->
			return unless !@card.autoplay
			video = @$refs?.media?.$el?.querySelector 'video'
			return unless video
			video.pause()

