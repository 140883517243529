
Flickity = require 'flickity' if process.client
import 'flickity/css/flickity.css'
import Card from './card'
import { padNum } from '~/services/helpers'
export default
	props: block: Object
	components: { Card }
	data: ->
		page: 0

	computed:
		classes: -> [
			@block.cardSize,
			"hide-mobile-landscape-down" if @isCarouselMobile
			"hide-mobile-landscape-up" if @isCarouselDesktop
		]
		carouselClasses: -> [
			"hide-mobile-landscape-up" if !@isCarouselDesktop,
			"hide-mobile-landscape-down" if !@isCarouselMobile
			@block.cardSize
		]

		isCarouselMobile: -> @block.mobileBehavior == "carousel"


		isCarouselDesktop: -> @block.desktopBehavior == "carousel"
		# pageOffset: -> "margin-left: #{@active*40}px"

		paginationOffset: -> @page * (@dotSize + 8)

		activePage: -> padNum @page + 1

	mounted: ->
		@$wait 400, =>
		# @$nextTick =>
			@initFlickity()
			@getPaginationDimension()

		window.addEventListener 'resize', @getPaginationDimension


	methods:
		updatePage: (index) -> @page = index
		goTo: (index) -> @$refs.carousel.goto index

		getPaginationDimension: () ->
			dot = @$el.querySelector '.flickity-page-dots .dot'
			return unless dot
			@dotSize = dot.getClientRects()?[0].width

		initFlickity: ->
			return if @flickity # prevent accidental double init

			@flickity = new Flickity @$refs.flickity,

				# Loop through the slides
				wrapAround: false

				# UI Elements
				prevNextButtons: false
				pageDots: true

				cellAlign: "left"
				# contain: true
				groupCells: false

				on:
					change: @updatePage
