
export default
	props:
		label:
			type: String
			required: true

		desktopImage:
			type: Array

		mobileImage:
			type: Array

		to:
			type: String

	methods:
		onClick: () ->
			return unless @desktopImage?.length || @mobileImage?.length
			@$store.commit 'imageModal/setState', {@desktopImage, @mobileImage, open: true}

