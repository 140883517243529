
export default

	props:

		label:
			type: String
			required: true

		href:
			type: String
			required: true

		logo:
			type: Array | Object
			required: true

	methods:

		# Prevent clicking if non-interactive
		onClick: (e) ->
			if @nonInteractive
			then e.preventDefault()
			else @$emit 'click'

